<template>
  <div>
    <!-- select 2 demo -->
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="Show data"
      hide-footer
      centered
      no-close-on-backdrop
      title="Filters"
    >
      <b-form>
        <b-form-group
          v-if="reportDomain == 'myskoolhpgs' && $store.state.userData.cId == 1"
          label="Campus"
          invalid-feedback="Campus is required."
          ref="camp"
        >
          <v-select
            v-model="filter.cID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="campuses"
            :clearable="false"
            :reduce="(val) => val.id"
            label="branch"
            :disabled="filterloading"
            placeholder="Select campus"
          />
        </b-form-group>

        <b-form-group
          label="Department"
          invalid-feedback="Department is required."
          ref="dep"
        >
          <v-select
            v-model="filter.dep"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="[{ name: 'All Departments', id: 0 }, ...departments]"
            :clearable="false"
            label="name"
            :reduce="(val) => val.id"
            ref="dep"
            :disabled="filterloading"
            placeholder="Select department"
          />
        </b-form-group>

        <b-form-group
          label="Status"
          invalid-feedback="status is required."
          ref="status"
        >
          <v-select
            v-model="currentStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            :reduce="(opt) => opt.value"
            label="text"
            :clearable="false"
            :disabled="filterloading"
            placeholder="Select status"
          />
        </b-form-group>
      </b-form>
      <b-row class="mt-2">
        <b-col style="display: flex; justify-content: end">
          <!-- @click="Filter()" -->
          <b-button
            @click="LoadData('filter')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
          >
            <span class="align-middle">Show data</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-cards"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="Print ID Card"
    >
      <b-form>
        <b-form-group
          label="Department"
          invalid-feedback="Department is required."
          ref="card_dep"
        >
          <v-select
            ref="card_dep"
            v-model="cardObj.depList"
            multiple
            :closeOnSelect="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="departments"
            :clearable="false"
            label="name"
            :reduce="(val) => val.id"
            :disabled="filterloading"
            placeholder="Select department"
            @input="setDep()"
          />
        </b-form-group>
      </b-form>
      <b-row class="mt-2">
        <b-col style="display: flex; justify-content: end">
          <b-button
            @click="printByDep()"
            style="padding: 12px 16px"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            :disabled="printing"
          >
            <span class="align-middle">Print</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <div>
      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Import</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12" class="pt-1">
              <b-button
                @click="$refs.importStaff.click()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="staffImporting"
                block
              >
                <b-spinner
                  v-if="staffImporting"
                  small
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <div v-else>
                  <input
                    type="file"
                    id="importStaff"
                    hidden
                    ref="importStaff"
                    @change="ImportStaff()"
                  />
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="align-middle">Staff</span>
                </div>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility2"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Staff Left</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility2 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12">
              <b-form-group
                label="Leaving Date"
                invalid-feedback="Date is required"
                ref="left_date"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.leaving_date"
                  class="form-control"
                  placeholder="Select date"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="saving"
                block
                @click="markLeft()"
              >
                <b-spinner
                  v-if="saving"
                  small
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <span v-else>Mark Left</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility3"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Staff Readmit</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility3 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12">
              <b-form-group
                label="Leaving Date"
                invalid-feedback="Date is required"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.leaving_date"
                  class="form-control"
                  placeholder="Select date"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Readmit Date"
                invalid-feedback="Date is required"
                ref="readmit_date"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.rejoining_date"
                  class="form-control"
                  placeholder="Select date"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="saving || savingRej"
                block
                @click="markReadmit(false)"
              >
                <b-spinner
                  v-if="saving"
                  small
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <span v-else>Readmit</span>
              </b-button>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                :disabled="saving || savingRej"
                block
                @click="markReadmit(true)"
              >
                <b-spinner
                  v-if="savingRej"
                  small
                  variant="light"
                  label="Spinning"
                ></b-spinner>
                <span v-else>Rejoin</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility4"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Transfer Staff</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility4 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-col md="12" class="mb-1">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="44"
                    :src="currentStaff.picture"
                    variant="light-primary"
                  />
                </template>

                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  style="margin-bottom: 4px"
                >
                  <b-badge variant="light-primary">
                    {{ currentStaff.name }}
                  </b-badge>
                </b-link>
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  style="margin-bottom: 4px"
                >
                  <b-badge variant="light-primary">
                    F/N: {{ currentStaff.father_name }}
                  </b-badge>
                </b-link>
                <b-link class="font-weight-bold d-block text-nowrap">
                  <b-badge variant="light-primary">
                    {{ currentStaff.designation }}
                  </b-badge>
                </b-link>
              </b-media>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Current Campus">
                <b-badge variant="primary">
                  {{ getCampus(currentStaff.campusID) }}
                </b-badge>
              </b-form-group>
              <b-form-group label="Current Department">
                <b-badge variant="primary">
                  {{ getDepName(currentStaff.departmentID) }}
                </b-badge>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Transfer to Campus"
                invalid-feedback="Campus is required."
                ref="ch_camp"
              >
                <v-select
                  v-model="campObj.newCampus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="campuses"
                  :clearable="false"
                  label="branch"
                  :reduce="(val) => val.id"
                  placeholder="Select campus"
                  @input="LoadDepByCamp()"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Department"
                invalid-feedback="Department is required."
                ref="ch_dep"
              >
                <v-select
                  v-model="campObj.departmentID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="depOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(val) => val.id"
                  :disabled="depLoading"
                  placeholder="Select department"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Designation"
                invalid-feedback="Designation is required"
                ref="ch_des"
              >
                <b-form-input
                  v-model.trim="campObj.designation"
                  placeholder="Enter designation"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Transfer Date"
                invalid-feedback="Date is required"
                ref="ch_date"
              >
                <flat-pickr
                  :config="config"
                  v-model="campObj.date"
                  class="form-control"
                  placeholder="Select date"
                />
              </b-form-group>
            </b-col>

            <b-col class="mt-1" md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                class="mr-1"
                block
                :disabled="request"
                @click="saveCh()"
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-else>Transfer</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility5"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0" v-html="sidebarTitle"></h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility5 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <b-row class="">
            <b-table
              v-if="reqData.length > 0"
              show-empty
              :items="reqData"
              :fields="reqFields"
              responsive
              hover
              small
            >
              <template #cell(request)="data">
                <div class="mb-25">
                  <b-badge variant="light-primary">
                    ID: {{ data.item.id }}
                  </b-badge>
                </div>
                <div>
                  <b-badge variant="light-primary">
                    {{
                      new Date(data.item.requestedOn).toLocaleDateString(
                        "en-UK",
                        {
                          year: "numeric",
                          day: "numeric",
                          month: "short",
                        }
                      )
                    }}
                  </b-badge>
                </div>
              </template>

              <template #cell(requestCategory)="data">
                <b-badge
                  variant="light-primary"
                  class="text-capitalize text-wrap"
                >
                  {{ data.value }}
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  @click="showReqDetails(data.item)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </template>
            </b-table>

            <b-col cols="12">
              <b-form-group
                label="Request on"
                invalid-feedback="Date is required"
                ref="req_date"
              >
                <flat-pickr
                  :config="config"
                  v-model="reqObj.requestedOn"
                  class="form-control"
                  placeholder="Select date"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Reason"
                invalid-feedback="Reason is required."
                ref="req_reason"
              >
                <b-form-textarea
                  placeholder="Enter reason"
                  rows="3"
                  v-model.trim="reqObj.request"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Request Type"
                invalid-feedback="Type is required."
                ref="req_st"
              >
                <v-select
                  v-model="reqObj.requestCategory"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="requestOptions"
                  :reduce="(opt) => opt.value"
                  label="text"
                  :clearable="false"
                  placeholder="Select status"
                />
              </b-form-group>
            </b-col>

            <b-col class="mt-1" md="12">
              <b-button
                variant="success"
                :disabled="savingReq"
                block
                @click="saveLeaveReq()"
              >
                <b-spinner v-if="savingReq" small type="grow" />
                <span v-else> Save </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-sidebar
        id="sidebar-right"
        ref="mySidebar"
        bg-variant="white"
        v-model="visibility6"
        sidebar-class="sidebar-lg"
        right
        no-header
        backdrop
        shadow
      >
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0" v-html="sidebarTitle"></h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="visibility6 = false"
          />
        </div>
        <b-form class="p-2" @submit.prevent>
          <RequestDetails :reqItem="detailObj" />
        </b-form>
        <sidebar-content />
      </b-sidebar>

      <b-card>
        <b-row class="mt-1">
          <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
            <b-button
              class=""
              @click="AddOpen()"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Add Staff</span>
            </b-button>
          </b-col>

          <b-col
            class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
            xl="6"
            lg="3"
            md="4"
            sm="12"
            cols="12"
          >
            <b-form-group class="ml-lg-2 ml-md-0 ml-sm-0 ml-0">
              <b-input-group>
                <b-input-group-prepend is-text @click="loadSearch()">
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model.trim="searchQuery"
                  placeholder="Search..."
                  @keyup.enter="loadSearch()"
                >
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="text-right" xl="2" lg="3" md="4" sm="12" cols="12">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              v-b-modal.modal-login
              block
            >
              <feather-icon icon="FilterIcon" class="mr-50" />
              <span class="align-middle">Filter</span>
            </b-button>
          </b-col>
          <b-col
            xl="2"
            lg="3"
            md="12"
            sm="12"
            cols="12"
            class="pl-lg-0 pr-50 mt-lg-0 mt-md-0 mt-sm-1 mt-1 d-flex justify-content-between align-items-start"
          >
            <b-form-group
              label=""
              style="flex: 1"
              class="ml-md-0 ml-sm-0 ml-0 mb-0 pr-50"
            >
              <v-select
                v-model="currentStatus"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="text"
                @input="LoadData('dd')"
                :clearable="false"
                placeholder="Select status."
              />
            </b-form-group>
            <b-button
              v-if="!rights.disableImport"
              @click="OpenImport()"
              variant="outline-primary"
              class="btn-icon"
              v-b-tooltip.hover.top
              title="Import"
            >
              <feather-icon icon="DownloadIcon" class="" />
            </b-button>
            <b-button
              v-if="!rights.disableExport"
              variant="outline-primary"
              v-b-tooltip.hover.top
              title="Export"
              class="btn-icon ml-50"
              :disabled="exporting"
              @click="exportData()"
            >
              <b-spinner v-if="exporting" small varaint="primary" />
              <feather-icon v-else icon="UploadIcon" class="" />
            </b-button>
          </b-col>
        </b-row>

        <b-row class="mt-lg-0 mt-md-1 mt-sm-1">
          <b-col cols="12" md="6" sm="6" class="pr-0">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 justify-content-end"
              prev-class="prev-item"
              next-class="next-item"
              @input="LoadData()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
          <b-col cols="12" md="6" sm="6" class="text-right pl-0 mt-1 mt-sm-0">
            <b-button
              @click="printIDs()"
              variant="outline-primary"
              class="btn-icon"
              :disabled="printing"
            >
              <b-spinner
                v-if="printing"
                variant="primary"
                label="loading"
                small
              ></b-spinner>

              <span v-if="!printing">
                <feather-icon icon="PrinterIcon" class="mr-50" /> Print ID Card
              </span>
              <span v-if="!printing && idList.length > 0"
                >({{ idList.length }})</span
              >
            </b-button>
            <b-button
              v-if="idList.length > 0"
              @click="unselect()"
              variant="outline-secondary"
              class="btn-icon ml-1"
              :disabled="printing"
            >
              <feather-icon icon="XIcon" class="mr-50" />
              <span>Unselect All</span>
            </b-button>
            <b-button
              @click="syncApp()"
              variant="outline-primary"
              class="btn-icon ml-1"
              :disabled="syncLoading"
              v-b-tooltip.hover.top
              title="Sync App Accounts"
            >
              <b-spinner
                v-if="syncLoading"
                variant="primary"
                label="loading"
                small
              ></b-spinner>
              <feather-icon v-else icon="SmartphoneIcon" class="" />
            </b-button>
          </b-col>
        </b-row>

        <b-table
          class="mt-1 rotate-table"
          :busy="dataLoading"
          ref="selectableTable"
          :items="items"
          :fields="showSalary && showSalary.valueBool ? fields2 : fields"
          show-empty
          responsive
          hover
          small
          :selectable="rights.edit"
          select-mode="multi"
          @row-selected="staffSelect($event)"
        >
          <template #empty="scope">
            <h3 class="mt-1 mb-1" style="text-align: center">
              No records found
            </h3>
          </template>
          <template #table-busy>
            <div
              class="d-flex justify-content-center mb-3"
              style="margin-top: 50px"
            >
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>
          <template #cell(index)="data">
            <!-- {{ data.index + 1 }} -->
            <b-badge variant="light-primary">
              <!-- {{ filters.indexOf(data.item) + 1 }} -->
              {{ (currentPage - 1) * perPage + data.index + 1 }}
            </b-badge>
          </template>

          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="data.item.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary" class="font-small-4">
                  {{ data.item.name }}
                </b-badge>
              </b-link>
              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  F/N: {{ data.item.father_name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary" class="mr-50">
                  ID: {{ data.item.id }}
                </b-badge>
                <b-badge variant="light-primary">
                  RFID: {{ data.item.rfID }}
                </b-badge>

                <b-badge
                  v-if="reportDomain == 'myskoolhpgs'"
                  variant="light-primary"
                  class="ml-50"
                >
                  EMP# {{ data.item.empCode }}
                </b-badge>
              </small>
              <b-link
                v-if="
                  data.item.status.toLowerCase() == 'left' &&
                  data.item.leaving_date
                "
                class="font-weight-bold d-block text-nowrap"
                style="margin-top: 4px"
              >
                <b-badge variant="light-primary">
                  {{
                    new Date(data.item.leaving_date).toLocaleDateString(
                      "en-UK",
                      {
                        year: "numeric",
                        day: "numeric",
                        month: "short",
                      }
                    )
                  }}
                </b-badge>
              </b-link>
              <b-link
                v-if="
                  data.item.status.toLowerCase() == 'present' &&
                  data.item.appointedOn
                "
                class="font-weight-bold d-block text-nowrap"
                style="margin-top: 4px"
              >
                <b-badge variant="light-primary">
                  {{
                    new Date(data.item.appointedOn).toLocaleDateString(
                      "en-UK",
                      {
                        year: "numeric",
                        day: "numeric",
                        month: "short",
                      }
                    )
                  }}
                </b-badge>
              </b-link>
            </b-media>
          </template>

          <template #cell(campus)="data">
            <b-badge variant="light-primary">
              {{ getCampus(data.item.campusID) }}
            </b-badge>
          </template>
          <template #cell(contact_number)="data">
            <b-badge variant="light-primary">
              {{ data.item.contact_number }}
            </b-badge>
          </template>
          <template #cell(department)="data">
            <b-badge variant="light-primary">
              {{ getDepName(data.item.departmentID) }}
            </b-badge>
          </template>
          <template #cell(qualification)="data">
            <b-badge variant="light-primary">
              {{ data.item.qualification }}
            </b-badge>
          </template>
          <template #cell(experience)="data">
            <b-badge variant="light-primary">
              {{ data.item.experience }}
            </b-badge>
          </template>
          <template #cell(designation)="data">
            <b-badge variant="light-primary">
              {{ data.item.designation }}
            </b-badge>
          </template>
          <template #cell(totalSalary)="data">
            <b-badge variant="light-primary">
              {{ data.item.totalSalary }}
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge variant="light-primary">
              {{ data.item.status }}
            </b-badge>
          </template>

          <template #head(actions)="data">
            <div class="text-center">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex">
              <b-button
                v-if="
                  $store.state.rights['manage-planner'] &&
                  data.item.status.toLowerCase() == 'present'
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.top
                title="Planner"
                class="btn-icon mr-1"
                @click="openPlanner(data.item.id)"
              >
                <feather-icon icon="FileTextIcon" class="" />
              </b-button>

              <b-button
                v-if="
                  rights.edit && data.item.status.toLowerCase() == 'present'
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.top
                title="Timetable"
                class="btn-icon mr-1"
                @click="timeTable(data.item.id)"
              >
                <feather-icon icon="ClipboardIcon" class="" />
              </b-button>

              <b-button
                v-if="rights.edit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.top
                title="Edit"
                class="btn-icon mr-1"
                :to="
                  data.item.status.toLowerCase() == 'applicant'
                    ? {
                        name: 'staff-application',
                        params: {
                          id: data.item.id,
                        },
                      }
                    : {
                        name: 'manage-staff',
                        params: {
                          id: data.item.id,
                        },
                      }
                "
              >
                <!-- @click="Edit(data.item.id)" -->
                <feather-icon icon="EditIcon" class="" />
              </b-button>

              <!-- v-if="
                  rights.editCampus && data.item.status.toLowerCase() == 'present'
                " -->
              <!-- <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="Transfer Staff"
                @click="openCh(data.item)"
              >
                <feather-icon icon="HomeIcon" />
              </b-button>  -->

              <b-button
                v-if="
                  ctObj &&
                  ctObj.valueBool &&
                  (rights.view || rights.edit) &&
                  data.item.status.toLowerCase() == 'present'
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Teaching Classes"
                class="btn-icon mr-1"
                @click="openClasses(data.item.id)"
              >
                <feather-icon icon="FileTextIcon" />
              </b-button>

              <b-button
                v-if="
                  slrObj &&
                  slrObj.valueBool &&
                  ['present'].includes(data.item.status.toLowerCase())
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                class="btn-icon mr-1"
                v-b-tooltip.hover.top
                title="Request"
                @click="openLeaveReq(data.item)"
              >
                <feather-icon icon="LogOutIcon" />
              </b-button>

              <b-button
                v-if="
                  !rights.hidePayroll &&
                  rights.edit &&
                  data.item.status.toLowerCase() == 'present'
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                v-b-tooltip.hover.top
                title="Left"
                class="btn-icon mr-1"
                @click="openLeft(data.item)"
              >
                <!-- @click="changeStatus(data.item, 'left')" -->
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                v-else-if="
                  !rights.hidePayroll &&
                  rights.edit &&
                  data.item.status.toLowerCase() == 'left'
                "
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                v-b-tooltip.hover.top
                title="Readmit"
                class="btn-icon mr-1"
                @click="openReadmit(data.item)"
              >
                <!-- @click="changeStatus(data.item, 'present')" -->
                <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
                v-if="rights.delete"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                v-b-tooltip.hover.top
                title="Delete"
                class="btn-icon"
                @click="Delete(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-1"
                @click="Perf(data.item.id)"
              >
                <feather-icon icon="StarIcon" />
              </b-button> -->

              <b-button
                v-if="showFaceId"
                variant="primary"
                class="btn-icon mr-1 ml-1"
                v-b-tooltip.hover.top
                title="Register Face ID"
                :disabled="faceIdLoading"
                @click="registerFaceId(data.item)"
              >
                <b-spinner v-if="faceIdLoading" small />
                <feather-icon v-else icon="UserIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ items.length }} of
              {{ totalCount == 0 ? items.length : totalCount }} entries</span
            >
          </b-col>
        </b-row>
      </b-card>
    </div>

    <!-- Add inside b-card after the table -->
    <b-modal
      v-model="showFaceIdModal"
      title="Register Face ID"
      size="lg"
      centered
      hide-footer
    >
      <iframe
        :src="enrollmentLink"
        style="width: 100%; height: 70vh; border: none"
        allow="camera;microphone"
        referrerpolicy="no-referrer"
      ></iframe>

      <b-row class="mt-2 bg-white p-2">
        <b-col class="d-flex justify-content-center">
          <!-- Removed copy link button -->
          <b-button variant="success" @click="shareWhatsApp">
            <feather-icon icon="MessageSquareIcon" class="mr-50" />
            <span>WhatsApp Link</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BSpinner,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BToast,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";
import readXlsxFile from "read-excel-file";

import * as XLSX from "xlsx";

import RequestDetails from "./RequestDetails.vue";
import Vue from "vue";

// Register the toast component
Vue.component("b-toast", BToast);

export default {
  components: {
    BInputGroup,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BAvatar,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BSpinner,
    BFormCheckbox,
    BForm,
    BContainer,
    BMediaAside,
    BImg,
    BMedia,
    //BLink,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    BFormTextarea,
    vSelect,
    flatPickr,
    RequestDetails,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
          editCampus: ch.editCampus,
          disableImport: ch.disableImport,
          disableExport: ch.disableExport,
          hidePayroll: ch.hidePayroll,
          hideDocuments: ch.hideDocuments,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];

      let obj = this.filterData.find(
        (el) =>
          el.db == this.$store.state.userData.db &&
          el.cID == this.$store.state.userData.cId
      );
      // console.log(obj);
      if (obj) {
        this.filter.dep = obj.depID;
        this.filter.cID = obj.campID
          ? obj.campID
          : this.$store.state.userData.cId;
        this.totalCount = obj.total;
        this.currentPage = obj.page;
        this.currentStatus = obj.currentStatus;
        this.LoadData();
      } else this.LoadData();

      // this.LoadData();
      this.LoadDep();
      this.loadSettings();
      this.LoadSettingsAll();
      if (this.reportDomain == "myskoolhpgs") {
        this.fields.splice(2, 0, { label: "campus", key: "campus" });
        this.fields2.splice(2, 0, { label: "campus", key: "campus" });
        this.LoadCampuses();
      }
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.id == this.searchQuery
        );
      });
    },
  },
  data() {
    return {
      rights: {},
      exporting: false,
      filterloading: false,
      filter: {
        cID: this.$store.state.userData.cId,
        dep: 0,
        status: "",
      },
      classes: [],
      contacterror: false,
      mydocloading: false,
      docsloading: "Upload",
      request: false,
      sidebarTitle: "Request",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      departments: [],
      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 50,
      totalCount: 0,
      errors: {
        status: false,
      },
      statusOptions: [
        { text: "Working", value: "present" },
        { text: "Left", value: "left" },
        { text: "Applicant", value: "applicant" },
      ],
      FilterStOptions: [
        { text: "All", value: "" },
        { text: "Working", value: "present" },
        { text: "Left", value: "left" },
        { text: "Applicant", value: "applicant" },
      ],
      currentStatus: "present",
      filterData: this.$store.state.staffFilter,
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      fields: [
        { label: "S.No", key: "index" },
        { label: "name", key: "name", stickyColumn: true, tdClass: "bg-white" },
        // { label: "father name", key: "father_name" },
        { label: "contact", key: "contact_number" },
        { label: "department", key: "department" },
        { label: "qualification", key: "qualification" },
        { label: "Designation", key: "designation" },
        // { label: "experience", key: "experience" },
        // { label: "Total Periods", key: "totalPeriods" },
        { label: "status", key: "status" },
        "actions",
      ],
      fields2: [
        { label: "S.No", key: "index" },
        { label: "name", key: "name", stickyColumn: true, tdClass: "bg-white" },
        { label: "contact", key: "contact_number" },
        { label: "department", key: "department" },
        { label: "qualification", key: "qualification" },
        { label: "Designation", key: "designation" },
        { label: "salary", key: "totalSalary" },
        { label: "status", key: "status" },
        "actions",
      ],
      currentSections: [],
      items: [],
      selected: null,
      rangeDate: null,
      myObj: {},
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      avatarURL:
        "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/110.png",
      staffImporting: false,
      staffData: [],
      idList: [],
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      printing: false,
      cardObj: {
        depList: [],
      },
      visibility2: false,
      visibility3: false,
      saving: false,
      savingRej: false,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      showSalary: null,
      syncLoading: false,
      ctObj: null,
      campuses: [],
      visibility4: false,
      currentStaff: {},
      campObj: {},
      depLoading: false,
      depOptions: [],
      visibility5: false,
      slrObj: null,
      reqData: [],
      reqFields: [
        { label: "request", key: "request" },
        { label: "Request type", key: "requestCategory" },
        { label: "status", key: "status" },
        { label: "actions", key: "actions" },
      ],
      reqObj: {},
      requestOptions: [
        { text: "Left", value: "Left" },
        {
          text: "Service No Longer Required",
          value: "Service No Longer Required",
        },
        { text: "Misconduct", value: "Misconduct" },
        { text: "Transfer", value: "Transfer" },
        { text: "Resigned", value: "Resigned" },
      ],
      savingReq: false,
      visibility6: false,
      detailObj: {},
      faceIdLoading: false,
      showFaceIdModal: false,
      enrollmentLink: "",
      currentStaffId: null,
      showFaceId: false,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async LoadReqData(id) {
      this.reqData = [];
      var obj = {
        url:
          this.$store.state.domain +
          "StaffRequests/LoadStaffData?db=" +
          this.$store.state.userData.db +
          "&tID=" +
          id,
        token: this.$store.state.userData.token,
      };
      this.reqData = await this.get(obj);
      // console.log(this.reqData);
    },
    async openLeaveReq(item) {
      this.reqObj = {
        id: 0,
        staffID: item.id,
        campusID: item.campusID,
        requestCategory: "Left",
        request: "",
        requestedOn: new Date().toJSON(),
        leavingDate: null,
        note: "",
        status: "pending",
        requestedBy: this.$store.state.userData.userID,
        approvedBy: 0,
      };

      await this.LoadReqData(item.id);

      let dt = item.appointedOn
        ? new Date(item.appointedOn).toLocaleDateString("en-UK", {
            year: "numeric",
            day: "numeric",
            month: "short",
          })
        : "";
      this.sidebarTitle = `${item.name} - ${item.designation}<br/>${dt}`;
      this.visibility5 = true;
    },
    CheckReqDate() {
      var elem = this.$refs["req_date"];
      if (!this.reqObj.requestedOn) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckReqReason() {
      var elem = this.$refs["req_reason"];
      if (this.reqObj.request.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveLeaveReq() {
      if (this.CheckReqReason() == true && this.CheckReqDate() == true) {
        this.reqObj.leavingDate = this.reqObj.requestedOn;
        // console.log(this.reqObj);
        this.savingReq = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "StaffRequests?db=" +
            this.$store.state.userData.db,
          body: this.reqObj,
          message: "Request saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.savingReq = false;
        if (status) {
          this.visibility5 = false;
        }
      }
    },
    showReqDetails(item) {
      this.detailObj = { ...item };
      this.visibility6 = true;
    },

    openCh(item) {
      this.currentStaff = { ...item };
      this.campObj = {
        tID: this.currentStaff.id,
        newCampus: 0,
        departmentID: 0,
        designation: this.currentStaff.designation,
        date: new Date().toJSON(),
        previousCampus: this.currentStaff.campusID,
        userID: this.$store.state.userData.userID,
      };

      this.visibility4 = true;
      this.depOptions = [];
    },
    async LoadDepByCamp() {
      this.depLoading = true;
      this.campObj.departmentID = 0;

      var obj = {
        url:
          this.$store.state.domain +
          "StaffDepartments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.campObj.newCampus,
        token: this.$store.state.userData.token,
      };
      this.depOptions = await this.get(obj);
      this.depLoading = false;
    },
    checkChCamp() {
      var elem = this.$refs["ch_camp"];
      if (this.campObj.newCampus == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkChDep() {
      var elem = this.$refs["ch_dep"];
      if (this.campObj.departmentID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkChDesig() {
      var elem = this.$refs["ch_des"];
      if (this.campObj.designation == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkChDate() {
      var elem = this.$refs["ch_date"];
      if (!this.campObj.date) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveCh() {
      this.checkChCamp();
      this.checkChDep();
      this.checkChDesig();
      this.checkChDate();
      if (
        this.checkChCamp() == false ||
        this.checkChDep() == false ||
        this.checkChDesig() == false ||
        this.checkChDate() == false
      ) {
        this.$bvToast.toast("Please select all the required fields", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
      // else if (this.chObj.previousCampus == this.chObj.newCampus) {
      //   this.$bvToast.toast("Current campus and transfer campus are same", {
      //     title: "Error!",
      //     variant: "danger",
      //     toaster: "b-toaster-top-center",
      //   });
      // }
      else {
        console.log(this.campObj);
        // let result = await Swal.fire({
        //   title: "Are you sure?",
        //   text: "Do you want to transfer the staff?",
        //   icon: "warning",
        //   showCancelButton: true,
        //   confirmButtonColor: "#d33",
        //   cancelButtonColor: "#3085d6",
        //   confirmButtonText: "Yes!",
        // });

        // if (result.isConfirmed) {
        //   this.request = true;

        //   // console.log(data);
        //   let status = await this.post({
        //     url:
        //       this.$store.state.domain +
        //       "Staff/TransferCampus?db=" +
        //       this.$store.state.userData.db,
        //     body: this.campObj,
        //     message: "Staff transferred successfully!",
        //     context: this,
        //     token: this.$store.state.userData.token,
        //   });
        //   this.request = false;
        //   if (status) {
        //     this.visibility4 = false;
        //     this.LoadData();
        //   }
        // }
      }
    },

    async syncApp() {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "This will syncronize mobile accounts.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Sync it!",
      });

      if (result.isConfirmed) {
        this.syncLoading = true;
        var status = this.get({
          url:
            this.$store.state.domain +
            "staff/SyncAccounts?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          message: "Accounts synced successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.syncLoading = false;
      }
    },

    getDepName(id) {
      let obj = this.departments.find((el) => el.id == id);
      return obj ? obj.name : "";
    },
    getCampus(id) {
      let obj = this.campuses.find((el) => el.id == id);
      return obj ? obj.branch : "";
    },
    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
    },
    staffSelect(row) {
      this.idList = row.reduce((acc, el) => {
        acc.push(el.id);
        return acc;
      }, []);

      // console.log(this.idList);
    },
    unselect() {
      this.$refs.selectableTable.clearSelected();
      // this.idList = [];
    },
    printIDs() {
      if (this.idList.length > 0) {
        console.log(this.idList);
        this.printing = true;
        var url =
          `https://${this.reportDomain}.myskool.app/Certificate/StaffCard?dbb=` +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&staffID=" +
          this.idList +
          "&depID=0";
        window.open(url, "_blank");
        this.printing = false;
      } else {
        this.cardObj = {
          depList: [],
        };
        this.$bvModal.show("modal-cards");
      }
    },
    printByDep() {
      if (this.CheckDepartment() == true) {
        console.log(this.cardObj);
        this.printing = true;
        var url =
          `https://${this.reportDomain}.myskool.app/Certificate/StaffCard?dbb=` +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&staffID=0&depID=" +
          this.cardObj.depList;
        window.open(url, "_blank");
        this.printing = false;
        this.$bvModal.hide("modal-cards");
      }
    },
    CheckDepartment() {
      var elem = this.$refs["card_dep"];
      if (this.cardObj.depList.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    setDep() {
      if (this.cardObj.depList.at(-1) == 0) {
        this.cardObj.depList = [0];
      } else {
        this.cardObj.depList = this.cardObj.depList.filter((el) => el != 0);
      }
      // console.log(this.cardObj.depList);
    },

    async exportData() {
      this.exporting = true;

      const expData = this.items.map((item) => ({
        id: item.id,
        name: item.name,
        father: item.father_name,
        spouse: item.spouse,
        cnic: item.cnic,
        contact: item.contact_number,
        qualification: item.qualification,
        designation: item.designation,
        status: item.status,
        appointment_date: item.appointedOn
          ? item.appointedOn.split("T")[0]
          : item.appointedOn,
        salary: item.totalSalary,
        address: item.address_current,
      }));

      const worksheet = XLSX.utils.json_to_sheet(expData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, "staff.xlsx");

      this.exporting = false;
    },

    async Filter() {
      // console.log(this.filter);
      this.$bvModal.hide("modal-login");
      this.dataLoading = true;
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "staff/filter?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dptID=" +
          this.filter.dep +
          "&status=" +
          this.filter.status,
        token: this.$store.state.userData.token,
      });
      this.items = myitem;
      if (this.items.length != 0) {
        this.totalRows = this.items.length;
      }
      this.currentStatus = this.filter.status;
      this.dataLoading = false;
    },
    OpenImport() {
      this.visibility = true;
    },
    async ImportStaff() {
      this.staffImporting = true;
      const schema = {
        id: {
          prop: "id",
          type: Number,
        },
        rfID: {
          prop: "rfID",
          type: String,
        },
        name: {
          prop: "name",
          type: String,
        },
        father_name: {
          prop: "father_name",
          type: String,
        },
        designation: {
          prop: "designation",
          type: String,
        },
        gender: {
          prop: "gender",
          type: String,
        },
        picture: {
          prop: "picture",
          type: String,
        },
        date_of_birth: {
          prop: "date_of_birth",
          type: Date,
        },
        address_current: {
          prop: "address_current",
          type: String,
        },
        address_permanent: {
          prop: "address_permanent",
          type: String,
        },
        contact_number: {
          prop: "contact_number",
          type: String,
        },
        emergency_contact_person: {
          prop: "emergency_contact_person",
          type: String,
        },
        emergency_contact_relation: {
          prop: "emergency_contact_relation",
          type: String,
        },
        emergency_number: {
          prop: "emergency_number",
          type: String,
        },
        qualification: {
          prop: "qualification",
          type: String,
        },
        experience: {
          prop: "experience",
          type: String,
        },
        campusID: {
          prop: "campusID",
          type: Number,
        },
        canPostNewsFeed: {
          prop: "canPostNewsFeed",
          type: Boolean,
        },
        totalPeriods: {
          prop: "totalPeriods",
          type: Number,
        },
        status: {
          prop: "status",
          type: String,
        },
        deviceID: {
          prop: "deviceID",
          type: String,
        },
        departmentID: {
          prop: "departmentID",
          type: Number,
        },
        availibility: {
          prop: "availibility",
          type: String,
        },
        documents: {
          prop: "documents",
          type: String,
        },
        expectedSalary: {
          prop: "expectedSalary",
          type: Number,
        },
        appointedOn: {
          prop: "appointedOn",
          type: Date,
        },
        basicSalary: {
          prop: "basicSalary",
          type: Number,
        },
        bonuses: {
          prop: "bonuses",
          type: Number,
        },
        totalSalary: {
          prop: "totalSalary",
          type: Number,
        },
        note: {
          prop: "note",
          type: String,
        },
        allowedLeaves: {
          prop: "allowedLeaves",
          type: Number,
        },
        joinsAt: {
          prop: "joinsAt",
          type: Date,
        },
        accountTitle: {
          prop: "accountTitle",
          type: String,
        },
        accountNumber: {
          prop: "accountNumber",
          type: String,
        },
        payMode: {
          prop: "payMode",
          type: String,
        },
        joinsAt: {
          prop: "leaving_date",
          type: Date,
        },
        department: {
          prop: "department",
          type: String,
        },
        cnic: {
          prop: "cnic",
          type: String,
        },
        leaves_allowed_from: {
          prop: "leaves_allowed_from",
          type: Date,
        },
      };
      // console.log("import staff....");
      const input = document.getElementById("importStaff");
      // console.log(input.files);
      await readXlsxFile(input.files[0], { schema })
        .then((rows) => {
          // `rows` is an array of rows
          this.staffData = rows.rows;
        })
        .catch((err) => {
          console.log(err);
          this.staffImporting = false;
        });
      // console.log(this.staffData);
      const chunkSize = 10000;
      let chunkLength = 0;

      for (let i = 0; i < this.staffData.length; i += chunkSize) {
        const chunk = this.staffData.slice(i, i + chunkSize);
        chunkLength += chunk.length;

        // console.log(chunk);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Staff/SaveAll?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: chunk,
          message: "Staff data imported.",
          importing: true,
          lastChunk: chunkLength == this.staffData.length,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (!status) this.staffImporting = false;
        else if (chunkLength == this.staffData.length)
          this.staffImporting = false;
      }
    },
    Edit(id) {
      // console.log("myRow1111111111111111", id);
      this.$router.push({
        name: "manage-staff",
        params: {
          id: id,
        },
      });
    },
    Perf(id) {
      this.$router.push({
        name: "staff-evaluation",
        params: {
          id: id,
        },
      });
    },
    openClasses(id) {
      this.$router.push({
        name: "teaching-classes",
        params: {
          id: id,
        },
      });
    },
    timeTable(id) {
      // console.log("id", id);
      this.$router.push({
        name: "timetable",
        params: {
          id: id,
        },
      });
    },
    openPlanner(id) {
      // console.log("id", id);
      this.$router.push({
        name: "manage-planner",
        params: {
          id: id,
        },
      });
    },
    AddOpen() {
      // console.log("Helloo");
      this.$router.push({
        name: "manage-staff",
        params: {
          id: 0,
        },
      });
    },
    openLeft(item) {
      this.myObj = { ...item };
      // console.log(this.myObj);
      this.visibility2 = true;
    },
    checkLeftDate() {
      var elem = this.$refs["left_date"];
      if (!this.myObj.leaving_date) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async markLeft() {
      if (this.checkLeftDate() == true) {
        this.saving = true;
        this.myObj.status = "left";

        var status = await this.put({
          url:
            this.$store.state.domain +
            "staff/" +
            this.myObj.id +
            "?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Staff marked as left successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        if (status) {
          this.visibility2 = false;
          this.LoadData();
        }
      }
    },
    openReadmit(item) {
      this.myObj = { ...item };
      // console.log(this.myObj);
      this.visibility3 = true;
    },
    checkReDate() {
      var elem = this.$refs["readmit_date"];
      if (!this.myObj.rejoining_date) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async markReadmit(val) {
      // no need to check on rejoin
      const check = val ? val : this.checkReDate();

      if (check) {
        if (val) {
          this.savingRej = true;
        } else {
          this.saving = true;
        }
        this.myObj.status = "present";

        var status = await this.put({
          url:
            this.$store.state.domain +
            "staff/" +
            this.myObj.id +
            "?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Staff readmitted succesfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.saving = false;
        this.savingRej = false;
        if (status) {
          this.visibility3 = false;
          this.LoadData();
        }
      }
    },
    async changeStatus(item, st) {
      let text = "";
      let message = "";
      if (st == "present") {
        text = "This will readmit the staff.";
        message = "Staff readmitted succesfully!";
      } else {
        text = "This will mark staff as left.";
        message = "Staff marked as left successfully!";
      }
      let result = await Swal.fire({
        title: "Are you sure?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        item.status = st;
        var status = await this.put({
          url:
            this.$store.state.domain +
            "staff/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: item,
          message: message,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "staff/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Staff deleted successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
      // Swal.fire({
      //   title: 'Are you sure?',
      //   text: "You won't be able to revert this!",
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes, Delete it!',
      // }).then(result => {
      //   if (result.isConfirmed) {

      //   }
      // });
    },
    async loadSettings() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["show_salary", "faceID"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.showSalary = res.find((el) => el.key == "show_salary");
      const faceIdSetting = res.find(
        (el) => el.key == "faceID" && el.value == "true"
      );
      this.showFaceId = faceIdSetting?.valueBool || false;
    },
    async LoadSettingsAll() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettingsAll?db=" +
          this.$store.state.userData.db,
        body: ["class_teacher_attd", "staff_request"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.ctObj = res.find((el) => el.key == "class_teacher_attd");
      this.slrObj = res.find((el) => el.key == "staff_request");
      // console.log(this.slrObj);
    },

    async LoadDep() {
      var obj = {
        url:
          this.$store.state.domain +
          "StaffDepartments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.departments = await this.get(obj);
      // if (this.departments.length > 0) {
      //   this.departments.unshift({ name: "All Departments", id: 0 });
      // } else this.departments = [];
    },

    async LoadData(text) {
      //  this.items =[];
      this.dataLoading = true;
      // let myitem = await this.get({
      //   url:
      //     this.$store.state.domain +
      //     "staff/filter?db=" +
      //     this.$store.state.userData.db +
      //     "&cId=" +
      //     this.$store.state.userData.cId +
      //     "&dptID=0&status=" +
      //     this.currentStatus,
      //   token: this.$store.state.userData.token,
      // });
      if (text == "filter") {
        this.$bvModal.hide("modal-login");
        this.currentPage = 1;
      } else if (text == "dd") {
        this.filter.dep = 0;
        this.currentPage = 1;
      }
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "staff/LoadDataPagination?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.filter.cID +
          "&dptID=" +
          this.filter.dep +
          "&status=" +
          this.currentStatus +
          "&page=" +
          this.currentPage,
        token: this.$store.state.userData.token,
        dashboard: true,
      });
      // console.log(myitem);
      this.items = myitem.data;
      this.totalCount = myitem.count;

      this.dataLoading = false;
      // console.log("items", this.items);

      let fData = {
        depID: this.filter.dep,
        campID: this.filter.cID,
        page: this.currentPage,
        currentStatus: this.currentStatus,
        total: this.totalCount,
        db: this.$store.state.userData.db,
        cID: this.$store.state.userData.cId,
      };
      let ind = this.filterData.findIndex(
        (el) =>
          el.db == this.$store.state.userData.db &&
          el.cID == this.$store.state.userData.cId
      );
      if (ind != -1) {
        this.filterData[ind] = { ...fData };
      } else {
        this.filterData.push(fData);
      }
      this.$store.commit("setStaffFilter", this.filterData);

      // console.log(this.filterData);
    },
    async loadSearch() {
      if (this.searchQuery.trim() == "") {
        this.currentPage = 1;
        this.LoadData();
      } else {
        this.dataLoading = true;
        let myitem = await this.get({
          url:
            this.$store.state.domain +
            "staff/Search?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&keyword=" +
            this.searchQuery +
            "&status=" +
            this.currentStatus,
          token: this.$store.state.userData.token,
        });
        this.items = myitem;
        this.totalCount = 0;
        this.currentPage = 1;
        this.dataLoading = false;
      }
    },
    async registerFaceId(item) {
      try {
        if (!item.rfID) {
          this.$root.$bvToast.toast("Please define RFID for staff", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
          return;
        }

        this.faceIdLoading = true;
        this.currentStaffId = item.id;

        const formattedDomain = this.reportDomain.replace(/_/g, "-");
        const campusId = this.$store.state.userData.cId;
        const baseUrl = `https://${formattedDomain}-${campusId}.cloudious.net/create-enrollment-link?cID=${campusId}`;
        const enrollmentUrl = baseUrl.replace(/^http:/, "https:");

        const response = await fetch(enrollmentUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: item.name,
            id: item.rfID,
          }),
        });

        const data = await response.json();
        this.enrollmentLink = data.enrollmentLink.replace(/^http:/, "https:");
        this.showFaceIdModal = true;

        // Add a small delay to ensure iframe is mounted
        setTimeout(() => {
          const iframe = document.querySelector("iframe");
          if (iframe) {
            iframe.setAttribute("allow", "camera;microphone");
          }
        }, 100);
      } catch (error) {
        console.error("Error registering face ID:", error);
        this.$root.$bvToast.toast("Error registering face ID", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.faceIdLoading = false;
      }
    },

    copyLink() {
      navigator.clipboard.writeText(this.enrollmentLink);
      this.$bvToast.toast("Link copied to clipboard", {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },

    async shareWhatsApp() {
      try {
        const response = await this.post({
          url: `${this.$store.state.domain}Chat/ShareFaceLink?db=${this.$store.state.userData.db}`,
          body: {
            tID: this.currentStaffId,
            link: this.enrollmentLink,
            isTeacher: false,
          },
          token: this.$store.state.userData.token,
          message: "Link sent on WhatsApp", // Add this line to use the built-in toast from post method
          context: this,
        });

        if (response) {
          this.showFaceIdModal = false;
        }
      } catch (error) {
        console.error("Error sharing link:", error);
        // Let the post method handle the error toast
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.rotate-table.table-responsive {
  transform: rotateX(180deg);
}
.rotate-table.table-responsive > ::v-deep.table {
  transform: rotateX(180deg);
}
/* adjust color on selection */
.table-responsive
  > ::v-deep.b-table
  > tbody
  .b-table-row-selected.table-active
  td.b-table-sticky-column {
  background-color: inherit !important;
}
/* no sticky column on smaller screen */
@media (max-width: 768px) {
  .table-responsive
    > ::v-deep.table.b-table
    > tbody
    > tr
    > .b-table-sticky-column {
    position: static !important;
  }
}
</style>
